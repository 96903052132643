<template>
  <!-- 学习记录 -->
  <div class="foxRight">
    <!-- 最近学习 -->
    <div v-if="list && list.length > 0" class="list">
      <div v-for="(item, index) in list" :key="index" class="item">
        <div class="content">
          <img class="item-img" :src="item.classesImg" />
          <div class="item-title">
            <div class="item-text elp">{{ item.chapterName }}</div>
            <div class="item-kcname ellipsis">{{ item.kName }}</div>
            <div class="data">
              <img
                style="display: inline-block; width: 14px; margin-right: 5px"
                src="@/assets/img/partner/dateTime.png"
                alt=""
              />
              {{ item.recordingTime }}
            </div>
          </div>
          <div class="colleges-learn fles-shrink" @click="detail(item)">
            继续学习
          </div>
        </div>
      </div>
    </div>

    <!-- 暂无记录 -->
    <div v-if="!list || list.length == 0" class="nothing">
      <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
      <div class="nothing-text">暂无记录哦~</div>
    </div>
    <el-pagination
      class="pagination colleges-pagination"
      :total="total"
      :current-page="pageNum"
      :page-sizes="[8, 16, 24, 40]"
      :page-size="pageSize"
      :pager-count="5"
      background
      layout="total, sizes, prev, pager, next"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <el-dialog
      title=""
      custom-class="dpPlay"
      :visible.sync="ifDialog"
      :show-close="false"
      width="80%"
      append-to-body
    >
      <iframe
        :src="`${href}/pages/videoRoom?videoId=${dialogList.id}&id=${
          dialogList ? dialogList.id : ''
        }&domainjwt=${domainjwt}&userId=${userId}&classId=${
          dialogList && dialogList.classesId ? dialogList.classesId : ''
        }`"
        width="100%"
        height="721px"
        frameborder="0"
        noResize
      />
    </el-dialog>
    <!-- 是否可以观看弹窗 -->
    <div v-if="showLookDetail" class="lookBg">
      <div class="ifLook">
        <div class="prompt">提示</div>
        <div class="contents">
          您当前正在<span>{{ lookDeatil ? lookDeatil.terminal : "" }}</span
          >观看<span>{{ lookDeatil ? lookDeatil.chapterName : "" }}</span
          >,请关闭后再行观看其他章节。
        </div>
        <div class="button" @click="closeLook">关闭</div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Know, courseCheckPlay } from "@/api/know";
const know = new Know();
import { getInfo, getToken, getDomainToken } from "@/api/cookies";
export default {
  data() {
    return {
      type: "",
      list: [],
      userId: null,
      total: 0,
      pageSize: 8,
      pageNum: 1,
      loading: null,
      ifDialog: false,
      href: process.env.VUE_APP_BASE_DpPlay,
      dialogList: {},
      domainjwt: getDomainToken(),
      lookDeatil: null,
      showLookDetail: false,
    };
  },
  created() {
    this.userId = localStorage.getItem("userId");
    this.getList();
  },
  methods: {
    async clik(index) {
      this.total = 0;
      this.pageNum = 1;
      this.list = [];
      this.pageSize = 8;
      this.type = index;
      await this.getList();
    },
    // 获取学习列表
    async getList() {
      console.log("==getList=====");
      this.loading = true;
      await know
        .myCourseList(this.pageSize, this.pageNum, this.type)
        .then((res) => {
          for (const item of res.rows) {
            Vue.prototype.dayLong(item.recordingTime).then((data) => {
              item.recordingTime = data;
            });
          }
          this.total = res.total;
          this.list = res.rows;
        });
      this.loading = false;
    },
    /* 切换每页条数 */
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    closeLook() {
      this.showLookDetail = false;
    },
    // 进入课堂
    detail(row) {
      switch (row.type) {
        case 1:
          const sub = {
            id: row.chapterId ? row.chapterId : undefined,
            classId: row.classesId ? row.classesId : undefined,
            startTime: row.startTime,
            chapterName: row.chapterName,
            classProductLine: row.productLine,
          };
          const data = Vue.prototype.export(sub);
          courseCheckPlay({
            courseType: "1",
            userId: this.userId,
            chapterId: row.chapterId ? row.chapterId : undefined,
            source: 1,
          }).then((res) => {
            if (res.code == 0) {
              this.$router.push(`/livebofang?${data}`);
            } else {
              this.lookDeatil = res.msg;
              this.showLookDetail = true;
            }
          });

          break;
        case 2:
          if (row.isOutsourcing == 1) {
            this.ifDialog = true;
            this.dialogList = row;
          } else {
            const sub2 = {
              id: row.chapterId ? row.chapterId : undefined,
              uservideoConfig: row.recordsConfig
                ? row.recordsConfig
                : undefined,
              courseId: row.kId ? row.kId : undefined,
              classId: row.classesId ? row.classesId : undefined,
              coursePackageId: row.coursePackageId
                ? row.coursePackageId
                : undefined,
              typebs: 2,
              chapterName: row.chapterName ? row.chapterName : undefined,
              classProductLine: row.productLine,
            };
            const data2 = Vue.prototype.export(sub2);
            courseCheckPlay({
              courseType: "2",
              userId: this.userId,
              chapterId: row.chapterId ? row.chapterId : undefined,
              source: 1,
            }).then((res) => {
              if (res.code == 0) {
                this.$router.push(`/dianbofang?${data2}`);
              } else {
                this.lookDeatil = res.msg;
                this.showLookDetail = true;
              }
            });
          }

          break;
        case 3:
          break;
        case 4:
          this.$router.push(`/openclassxq?id=${row.classesId}`);
          break;
        case 5:
          this.$router.push(
            `/ketangziliao?id=${row.kId}&classId=${row.classesId}`
          );
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.foxRight {
  width: 100%;
  padding-bottom: 20px;
  //隐藏滚动条
  ::-webkit-scrollbar {
    width: 0 !important;
    scrollbar-width: none !important;
    -ms-overflow-style: none;
  }
}
.list {
  width: 100%;
  margin-top: 24px;
  background-color: #fff;
  .item {
    width: 1049px;
    height: 134px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.06);
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    padding: 10px;
    margin-bottom: 20px;
    .content {
      display: flex;
      height: 114px;
      align-items: center;
      .item-img {
        width: 205px;
        height: 114px;
        border-radius: 6px;
        opacity: 1;
        flex-shrink: 0;
        margin-right: 12px;
      }
      .item-title {
        width: calc(100% - 204px - 100px);
        height: 100%;
        position: relative;
        .item-text {
          height: 16px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 16px;
          margin-top: 8px;
        }
        .item-kcname,
        .data {
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          line-height: 12px;
          height: 12px;
        }
        .item-kcname {
          margin-top: 20px;
        }
        .data {
          position: absolute;
          bottom: 20px;
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #006de7;
        }
      }
      .colleges-learn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 110px;
        height: 36px;
        border-radius: 18px 18px 18px 18px;
        opacity: 1;
        border: 1px solid #006de7;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #006de7;
      }
    }
  }
}
.lookBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  .ifLook {
    width: 500px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    .contents {
      width: 380px;
      margin: 20px auto;
      & > span {
        color: red;
      }
    }
    .button {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
}
</style>

<style lang="less"></style>
